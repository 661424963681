var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Elmw8MWHJKPQ8VU6ExE1L"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the server.
// The config you add here will be used whenever middleware or an Edge route handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_RELEASE = process.env.NEXT_PUBLIC_SENTRY_RELEASE;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        'apichakradev.chakrarewards.com',
        'apichakragcp.chakrarewards.com',
        /^\//
      ]
    })
  ],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production' ?? false,
  release: SENTRY_RELEASE
});
