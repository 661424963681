import Cookies from 'js-cookie';
import { CookieName } from './constants';

export const steps = [
  {
    selector: '[data-tut="tour-guide-card-kf"]',
    content: () => (
      <>
        <p className="font-semibold">Add card by number !</p>
        <div className="text-gray-400 text-sm">
          Sudah bergabung dengan salah satu brand di Chakra Rewards? Anda bisa menghubungkan nya
          disini !
        </div>
        <button
          className="button button--primary button--block button--auto-base text-sm mt-4"
          onClick={() => {
            Cookies.set(CookieName.tour, 'LyLy');
            window.location.href = '/card/add-card/kc';
          }}>
          Add Card Now
        </button>
      </>
    )
  }
];
