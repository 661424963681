import { createContext, useContext, useState } from 'react';

export interface IModalContext {
  modal: any;
  openModal: any;
  closeModal: any;
}

export const ModalContext = createContext(null as any);

export const useModal = () => {
  return useContext<IModalContext>(ModalContext);
};

const ModalContextProvider = (props: any) => {
  const { children } = props;

  const [modal, setModal] = useState<any>();

  const openModal = (value: any) => {
    setModal(value);
  };

  const closeModal = () => {
    setModal(null as any);
  };

  return (
    <ModalContext.Provider value={{ modal, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
