import { QueryClient } from '@tanstack/react-query';

const RQClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5000,
      refetchOnWindowFocus: false
    }
  }
});

export default RQClient;
