interface IStorage {
  pin: string | undefined;
  login: string | undefined;
  register: string | undefined;
  otp: string | undefined;
  profile: string | undefined;
  token: string | undefined;
  registerProfile: string | undefined;
  card: string | undefined;
  userCard: string | undefined;
  selectedAddress: string | undefined;
  cart: string | undefined;
}

export const storage: IStorage = {
  pin: process.env.NEXT_PUBLIC_CONST_LOYALTY_PIN,
  login: process.env.NEXT_PUBLIC_CONST_LOYALTY_LOGIN,
  register: process.env.NEXT_PUBLIC_CONST_LOYALTY_REGISTER,
  otp: process.env.NEXT_PUBLIC_CONST_LOYALTY_OTP,
  profile: process.env.NEXT_PUBLIC_STORAGE_TOKEN_KEY_NAME_PROFILE,
  token: process.env.NEXT_PUBLIC_STORAGE_TOKEN_KEY_NAME_CHAKRA,
  registerProfile: process.env.NEXT_PUBLIC_CONST_LOYALTY_PROFILE,
  card: process.env.NEXT_PUBLIC_STORAGE_TOKEN_KEY_NAME_CARD,
  userCard: process.env.NEXT_PUBLIC_STORAGE_TOKEN_KEY_NAME_USER_CARD,
  selectedAddress: process.env.NEXT_PUBLIC_STORAGE_TOKEN_KEY_NAME_SELECTED_ADDRESS,
  cart: process.env.NEXT_PUBLIC_STORAGE_TOKEN_KEY_NAME_CART
};

export enum CookieName {
  token = '_Tk',
  user = '_Usm',
  qr = '_Qrc',
  point = '_Bp',
  tour = '_Tgd',
  struk = '_Strk',
  phone = '_Phn',
  fprsId = '_fprs',
  tokenDmqi = '_Tk_Dm'
}

export const HistoryStatus: any = {
  success: 'Success',
  onprocess: 'On Process',
  needverification: 'On Process',
  cancel: 'Failed'
};

export const HistoryColorStatus: any = {
  success: 'success',
  onprocess: 'warning',
  needverification: 'warning',
  cancel: 'danger'
};

export const HistoryRedeemDesc: any = {
  onprocess: 'Proses tukar poin anda sedang di proses',
  needverification: 'Proses tukar poin anda sedang di proses',
  cancel: 'Proses tukar poin anda gagal di proses'
};
