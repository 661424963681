import type { AppProps } from 'next/app';
import Router from 'next/router';
import dynamic from 'next/dynamic';
import React from 'react';

const Preloader = dynamic(() => import('@components/core/preloader'));
const OfflineDetector = dynamic(() => import('@components/core/offline-detector'));
const ModalDialog = dynamic(() => import('@components/core/dialog'));

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import '@styles/globals.scss';

// Binding events
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

import { QueryClientProvider } from '@tanstack/react-query';
import { MantineProvider } from '@mantine/core';
import { ToastContainer } from 'react-toastify';
import { TourProvider } from '@reactour/tour';
import LoadingContextProvider from '@contexts/loading';
import ModalContextProvider from '@contexts/modal';
import RQClient from '@lib/RQClient';
import { steps } from '@utils/tour-steps';

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <QueryClientProvider client={RQClient}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            colorScheme: 'light'
          }}>
          <LoadingContextProvider>
            <ModalContextProvider>
              <TourProvider
                steps={steps}
                showBadge={false}
                showNavigation={false}
                showCloseButton={true}
                onClickClose={() => console.log(123)}>
                <Preloader />
                <Component {...pageProps} />
                <ToastContainer
                  autoClose={3000}
                  position="top-right"
                  theme="colored"
                  hideProgressBar
                  closeOnClick
                  pauseOnHover
                />
                <OfflineDetector />
                <ModalDialog />
              </TourProvider>
            </ModalContextProvider>
          </LoadingContextProvider>
        </MantineProvider>
      </QueryClientProvider>
    </>
  );
};

// export default wrapper.withRedux(MyApp);
export default MyApp;
